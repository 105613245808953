import React, {useState, useEffect} from 'react';
import { Container, Form } from "react-bootstrap";
import { navigate, Link } from "gatsby";

// Styles
import './LoginScreen.scss';

// Components
import CustomTextField from "components/CustomTextField";
import CustomButton from "components/CustomButton";
import {CustomLinks} from "components/common/menuUtils";

import { handleLogin, isBrowser, setUser, getUser, isLoggedIn } from "../../../services/auth"


const LoginScreen = () => {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(false)
    const [showerror, setShowerror] = useState(false)
    const [error, setError] = useState()
    const [isAuth, setIsAuth] = useState(isLoggedIn())
    const myRef = React.createRef();
    
    const handlechange = event => {
        // remove initial empty spaces
        event.target.value = event.target.value.trimStart()
    }
    
    const handleSubmit = event => {
        const form = event.currentTarget;

        //console.log("form", form);    
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
          setError("Enter valid email-id and password.")
          setShowerror(true)
          setValidated(true)
          myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
        } else {
          event.preventDefault()
          setLoading(true);
          const formsdata = event.target
          const params = {}
          Object.keys(formsdata).map(
            key =>
              (params[formsdata[key].name] = formsdata[key].checked
                ? "yes"
                : formsdata[key].value)
          )
    
          if (params.role === "agents") {
            setShowerror(false)
            const handleAuth = async params => {
              const loginStatus = await handleLogin(params)
              if (loginStatus.message === "OK" && isLoggedIn()) {  
                setTimeout(function(){
                  setLoading(false);
                  navigate(`/${CustomLinks.dashboard}/`)
                },2000);            
              } else {
                setLoading(false);
                setError(loginStatus.message)
                setShowerror(true)
              }
            }
            if (!isLoggedIn()) {
              handleAuth(params)
            }
          } else {
            setLoading(false);
            setError("Invalid Role!")
            setShowerror(true)
          }
        }
    }

    return (
        <div className="login-screen-wrapper">
            <Container>
                <div className="login-card">
                    <div className="login-agent-icon">
                        <img
                            src={ require( `../../../images/login-agent-icon.png` ).default }
                            alt="login-agent"
                        />
                    </div>
                    <h1 className="general-heading">Agent Log in</h1>

                    <div ref={myRef}></div>
                    {showerror && (
                        <div className="alert alert-danger mt-4">
                            {error}
                        </div>
                    )}
                    <div className="form-wrapper">
                        {loading && <div className='frm_loader_bg'></div> }

                        <Form enctype="multipart/form-data"
                        action="/thank-you/"
                        method="post"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}> 

                            <input type="hidden" name="form_type" value={"Agent Login"}/>
                            <input type="hidden" name="role" value="agents" />

                            <div className="form-fields-wrapper">
                                <CustomTextField
                                    type="email"
                                    label="YOUR EMAIL"
                                    defaultValue=""
                                    name="username"
                                    handlechange={handlechange}
                                    required={true}
                                />
                                <CustomTextField
                                    type="password"
                                    label="PASSWORD"
                                    defaultValue=""
                                    name="password"
                                    handlechange={handlechange}
                                    required={true}
                                />
                            </div>
                            <div>
                                <CustomButton
                                    variant="green"
                                    value="SUBMIT"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                    <div className="other-account-actions hide-on-mobile">
                        <Link to={"/"+CustomLinks.forgot_password+"/"} className="forget_pwd_link mb-2">
                            Forgotten your password?
                        </Link>
                        <Link to={"/"+CustomLinks.register+"/"} className="no-decoration">
                            Don't have an account yet?
                            <Link to={"/"+CustomLinks.register+"/"} className="register-here">Register here</Link>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LoginScreen;
